<template>
  <div
    class="flex h-screen flex-col items-center justify-center bg-gradient-to-b from-[#53BE7E] to-[#C1E8D0] drop-shadow-md"
  >
    <div class="h-fit w-2/5 max-w-[700px] rounded-3xl bg-white px-14 py-10">
      <div class="mb-10 h-[54px] w-full">
        <img
          class="mx-auto h-full"
          src="/logo-chat-faq-full-on-light.svg"
          alt="logo"
        />
      </div>
      <slot />
    </div>
    <div class="mt-9">
      <p class="mb-4 text-center text-misc-text-subtle">
        Die Übertragung ist durch eine SSL-Verschlüsselung geschützt.
      </p>
      <p class="text-center text-misc-text-subtle">
        @2023
        <NuxtLink
          class="underline underline-offset-2 transition-colors duration-150 hover:text-black"
          to="https://www.aifinyo.de/"
          external
        >
          aifinyo AG
        </NuxtLink>
        •
        <NuxtLink
          class="underline underline-offset-2 transition-colors duration-150 hover:text-black"
          to="https://www.chatfaq.ai/impressum/"
          external
        >
          Impressum
        </NuxtLink>
        •
        <NuxtLink
          class="underline underline-offset-2 transition-colors duration-150 hover:text-black"
          to="https://www.chatfaq.ai/agb/"
          external
        >
          AGB
        </NuxtLink>
        •
        <NuxtLink
          class="underline underline-offset-2 transition-colors duration-150 hover:text-black"
          to="https://www.chatfaq.ai/datenschutzerklaerung/"
          external
        >
          Datenschutzhinweis
        </NuxtLink>
      </p>
    </div>
  </div>
</template>
